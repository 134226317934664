import { Button, Modal, Space, Typography } from 'antd';
import { useForm } from '../context';
import { AxiosError } from 'axios';

const IdempotencyModal = () => {
  const { isRefused, setRefused, error, forceSubmit } = useForm();
  const code = (error as AxiosError<any>)?.response?.data?.code;

  const shouldShow = code === 'IDEMPOTENCY' && isRefused;

  const tryAgain = () => {
    setRefused(false);
    forceSubmit();
  };

  return (
    <Modal closable={false} open={shouldShow} title="Tentativa de compra" footer={null} centered>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Text className="my-3">
          Já existe uma tentativa de compra ou alguma compra realizada, <strong>deseja tentar novamente?</strong>
        </Typography.Text>
        <Button className="rounded-md w-full" type="primary" size="large" onClick={tryAgain}>
          Tentar novamente
        </Button>
        <Button className="rounded-md w-full" type="default" size="large" onClick={() => setRefused(false)}>
          Cancelar
        </Button>
      </Space>
    </Modal>
  );
};

export default IdempotencyModal;
