import axios from 'axios';
import { TRACK_API_URL } from '../settings';
import { ITrack } from '../interfaces/ITrack';
import { v4 } from 'uuid';

class TrackService {
  async trackEvent(payload: ITrack) {
    try {
      const eventId = localStorage.getItem('eventId') ?? v4();
      const fingerprint = localStorage.getItem('fingerprint') ?? v4();

      localStorage.setItem('eventId', eventId);
      localStorage.setItem('fingerprint', fingerprint);

      const { data } = await axios.post(`${TRACK_API_URL}/events`, {
        ...payload,
        eventId,
        fingerprint,
      });

      return data;
    } catch (error) {
      console.error('Error tracking event', error);
    }
  }
}

export const trackService = new TrackService();
