import { memo, useEffect, useState } from 'react';
import { IStyledProp } from '../../interfaces/IStyledProp';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { checkoutService } from '../../services/CheckoutService';
import styled from '@emotion/styled';
import { Col, Layout, Row, Typography, Image, Button, Alert, Card, Divider, message, Spin, Space } from 'antd';
import {
  CheckCircleFilled,
  BarcodeOutlined,
  ClockCircleOutlined,
  CopyOutlined,
  CheckCircleOutlined,
  CreditCardOutlined,
  DollarOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import CardSuccess from './CardSuccess';
import { Footer } from 'antd/es/layout/layout';
import { getCdnUrl } from '../../components/Form/helper';
import { trackService } from '../../services/TrackService';
import { setupGoogleTagManager } from '../../helpers/functions';

const { Text } = Typography;

const Success = memo<IStyledProp>(({ className }) => {
  const { transactionId: id } = useParams();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const hasPrevState = !!location.state?.data?.id;

  let { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: ['result', id],
    queryFn: () => {
      if (!id) {
        return;
      }

      return checkoutService.getResultByTransactionId(id);
    },
    enabled: !!id && !hasPrevState,
  });

  data = hasPrevState ? location.state.data : data;

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!data) return;

    const hasPix = !!data.payments?.find((trans: any) => trans.paymentMethod === 'pix');

    if (!hasPix) return;

    if (hasPrevState) {
      navigate(location.pathname, {
        state: { data: undefined },
        replace: true,
      });
    }

    if (data.status == 'paid') return;

    const id = setInterval(() => {
      queryClient.invalidateQueries({
        queryKey: ['result', id],
        refetchType: 'all',
      });

      refetch();
    }, 5000);
    return () => {
      clearInterval(id);
    };
  }, [data, queryClient, id, refetch]);

  useEffect(() => {
    if (!data) return;

    if (!data.redirectUrl) return;

    if (data.status != 'paid') return;

    setIsRedirecting(true);
    setTimeout(() => {
      window.location.replace(data.redirectUrl);
    }, 10000);
  }, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }

    trackService.trackEvent({
      eventName: 'Purchase',
      eventTime: Date.now(),
      eventSourceUrl: window.location.href,
      checkoutId: data.checkout.id!,
      teamId: data.checkout.teamId!,
      data: {
        deviceInfo: {
          userAgent: navigator.userAgent,
        },
        transaction: {
          transactionId: data.id!,
          status: data.status,
          amount: data.amount,
          currency: 'BRL',
        },
        customer: data.customer!,
        payments: data.payments,
        items: data.items,
      },
    });

    const gtmPixel = data?.checkout?.pixels?.find((pixel: any) => pixel.type == 'google-tag-manager');

    if (!gtmPixel) return;

    const hasGtm = gtmPixel.config?.events?.includes('purchase');

    if (gtmPixel && hasGtm) {
      let validGtm = setupGoogleTagManager(gtmPixel.config.id!);

      if (validGtm) {
        let arrItems = data.items.map((item: any) => {
          return {
            description: item.description,
            code: item.code,
            amount: item.amount && item.amount > 0 ? item.amount / 100 : 0,
            quantity: item.quantity,
            type: item.type,
          };
        });

        let arrPayments = data.payments.map((payment: any) => {
          return {
            paymentMethod: payment.paymentMethod,
            value: payment.value && payment.value > 0 ? payment.value / 100 : 0,
          };
        });

        window.dataLayer.push({
          event: 'Purchase',
          eventSourceUrl: window.location.href,
          checkoutId: data.checkout.id!,
          data: {
            amount: data.amount && data.amount > 0 ? data.amount / 100 : 0,
            deviceInfo: {
              userAgent: navigator.userAgent,
            },
            transaction: {
              transactionId: data.id!,
              status: data.status,
              amount: data.amount && data.amount > 0 ? data.amount / 100 : 0,
              currency: 'BRL',
            },
            customer: data.customer!,
            payments: arrPayments,
            items: arrItems,
          },
        });
      }
    }
  }, [data]);

  if (isLoading || !data) {
    return <Spin tip="Carregando..." fullscreen size="large"></Spin>;
  }

  if (isError) {
    return <div>Error: {error?.message}</div>;
  }

  const copyText = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      message.info('Código copiado com sucesso...');
    } catch (err) {
      message.error('Erro ao copiar...');
    }
  };

  const openBoleto = (text: string) => {
    try {
      window.open(text, '_blank');
    } catch (err) {
      message.error('Erro ao abrir o boleto...');
    }
  };

  // Função para formatar a data no formato "dd/mm/yyyy"
  const formatarData = (dataString: string | number | Date) => {
    const data = new Date(dataString);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  return (
    <div
      className={className}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#EBEFF4' }}
    >
      <Layout className="bg-gray-100" style={{ minWidth: 380, maxWidth: 620, margin: 10, backgroundColor: '#fff' }}>
        <div className={className} style={{ backgroundColor: '#EBEFF4' }}>
          <Row className="bg-green-900 rounded-t-lg h-16 items-center px-3">
            <Col>
              <CheckCircleOutlined style={{ color: '#ffffff', fontSize: 26 }} />
              <Text className="pl-2 text-2xl text-white">Pedido recebido</Text>
            </Col>
          </Row>
        </div>

        {data.isTest && (
          <Row className="mb-1 mt-1">
            <Col span={24} className="flex justify-center">
              <Space direction="vertical" style={{ width: '100%' }}>
                <Alert
                  message="Ambiente de Teste"
                  description="Esta é uma simulação de compra, nenhum valor será cobrado."
                  type="warning"
                  showIcon
                />
              </Space>
            </Col>
          </Row>
        )}

        {isRedirecting && (
          <Row>
            <Card>
              <Space style={{ width: '100%' }}>
                <Text className="text-gray-500">
                  <ClockCircleOutlined style={{ color: '#15803D', fontSize: 18, marginRight: 5 }} />
                  Redirecionando em 5 segundos para a página de obrigado, caso não seja redirecionado clique no botão ao
                  lado.
                </Text>
                <Button type="default" onClick={() => window.location.replace(data.redirectUrl)}>
                  CLIQUE AQUI
                </Button>
              </Space>
            </Card>
          </Row>
        )}

        {data.payments.map((trans: any) => (
          <div>
            {trans.paymentMethod === 'pix' && (
              <>
                {trans.status == 'paid' ? (
                  <CardSuccess
                    name={
                      <span>
                        <QrcodeOutlined style={{ fontSize: 16, marginRight: 5 }} />
                        Pix
                      </span>
                    }
                  />
                ) : (
                  <Card
                    title={
                      <span>
                        <QrcodeOutlined style={{ fontSize: 16, marginRight: 5 }} />
                        Pix
                      </span>
                    }
                    className="mx-2 mt-2"
                  >
                    <Row>
                      <Col>
                        <Text className="text-lg font-bold text-gray-500">
                          <ClockCircleOutlined style={{ color: '#15803D', fontSize: 18, marginRight: 5 }} />
                          Pague o Pix para finalizar a compra.
                        </Text>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <Text className="text-sm text-gray-500">
                          Quando o pagamento for aprovado, você receberá em{' '}
                          <span className="font-bold">{data.customer.email}</span> as informações sobre sua(s)
                          compra(s). Não se esqueça de conferir sua caixa de spam.
                        </Text>
                      </Col>
                    </Row>
                    <Row className="bg-white justify-center items-cente mt-5">
                      <Col className=" justify-center items-center">
                        <Text className="text-sm  text-gray-500">Código Pix</Text>
                      </Col>
                    </Row>
                    <Row className="bg-white justify-center items-cente mt-2 text-lg font-bold">
                      <Col className=" justify-center items-center">
                        <Text className="text-sm  text-gray-500">
                          <Image preview={false} src={trans.response?.pix?.qrCode} width={180} height={180} />
                        </Text>
                      </Col>
                    </Row>
                    <Row className="bg-white justify-center items-cente mt-0 text-lg font-bold">
                      <Col className=" justify-center items-center">
                        <Text className="text-sm  text-black">{trans.response?.pix?.qrCodeText}</Text>
                      </Col>
                    </Row>
                    <Row className="flex bg-white justify-center items-cente mt-5">
                      <Col className="flex items-center justify-center">
                        <Button
                          className="rounded-md"
                          type="default"
                          icon={<CopyOutlined />}
                          size="large"
                          block
                          onClick={() => copyText(trans.response?.pix?.qrCodeText)}
                        >
                          COPIAR CÓDIGO PIX
                        </Button>
                      </Col>
                    </Row>
                    <Row className="bg-white justify-center items-cente mt-5">
                      <Col className=" justify-center items-center">
                        <Alert
                          description={
                            <span className="text-xs">
                              {<CheckCircleFilled />} Abra o aplicativo do seu banco e acesse a área Pix<br></br>
                              {<CheckCircleFilled />} Selecione a opção pagar com código Pix Copia e Cola e cole o
                              código no espaço indicado no aplicativo<br></br>
                              {<CheckCircleFilled />} Após o pagamento, você receberá por email as informações de acesso
                              à sua compra
                            </span>
                          }
                          type="info"
                          showIcon
                          message="Passo a passo para pagar com Pix"
                        />
                      </Col>
                    </Row>
                  </Card>
                )}
              </>
            )}
            {trans.paymentMethod === 'boleto' && (
              <>
                {trans.status == 'paid' ? (
                  <CardSuccess
                    name={
                      <span>
                        <BarcodeOutlined style={{ fontSize: 16, marginRight: 5 }} />
                        Boleto
                      </span>
                    }
                  />
                ) : (
                  <Card
                    title={
                      <span>
                        <BarcodeOutlined style={{ fontSize: 16, marginRight: 5 }} />
                        Boleto
                      </span>
                    }
                    className="mx-2 mt-2"
                  >
                    <Row>
                      <Col>
                        <Text className="text-lg font-bold text-gray-500">
                          <ClockCircleOutlined style={{ color: '#15803D', fontSize: 18, marginRight: 5 }} />
                          Pague o boleto até {formatarData(trans.response?.boleto?.expirationDate)} para concluir a
                          compra.
                        </Text>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <Text className="text-sm text-gray-500">
                          Quando o pagamento for aprovado, você receberá em{' '}
                          <span className="font-bold">{data.customer.email}</span> as informações sobre sua(s)
                          compra(s). Não se esqueça de conferir sua caixa de spam.
                        </Text>
                      </Col>
                    </Row>
                    <Row className="bg-white justify-center items-cente mt-5">
                      <Col className=" justify-center items-center">
                        <Text className="text-sm  text-gray-500">Código de Barras</Text>
                      </Col>
                    </Row>
                    <Row className="bg-white justify-center items-cente mt-2 text-lg font-bold">
                      <Col className=" justify-center items-center">
                        <Text className="text-sm  text-gray-500">
                          <Image preview={false} src={trans.response?.boleto?.barcode} height={50} />
                        </Text>
                      </Col>
                    </Row>
                    <Row className="bg-white justify-center items-cente mt-0 text-lg font-bold">
                      <Col className=" justify-center items-center">
                        <Text className="text-xs  text-black">{trans.response?.boleto?.digitableLine}</Text>
                      </Col>
                    </Row>
                    <Row gutter={10} className="flex bg-white justify-center items-cente mt-5">
                      <Col span={12} className="items-center justify-center">
                        <Button
                          className="rounded-md"
                          type="default"
                          icon={<BarcodeOutlined />}
                          size="large"
                          block
                          onClick={() => openBoleto(trans.response?.boleto?.url)}
                        >
                          VISUALIZAR
                        </Button>
                      </Col>
                      <Col span={12} className="flex items-center justify-center">
                        <Button
                          className="rounded-md"
                          type="default"
                          icon={<CopyOutlined />}
                          size="large"
                          block
                          onClick={() => copyText(trans.response?.boleto?.digitableLine)}
                        >
                          COPIAR CÓDIGO
                        </Button>
                      </Col>
                    </Row>
                    <Row className="bg-white justify-center items-cente mt-5">
                      <Col className=" justify-center items-center">
                        <Alert
                          description={
                            <span>
                              O pagamento com boleto pode levar <span className="font-bold">até 3 dias úteis</span> para
                              ser processado.
                            </span>
                          }
                          type="info"
                          showIcon
                        />
                      </Col>
                    </Row>
                  </Card>
                )}
              </>
            )}

            {trans.paymentMethod === 'credit_card' && (
              <CardSuccess
                name={
                  <span>
                    <CreditCardOutlined style={{ fontSize: 16, marginRight: 5 }} />
                    Cartão de crédito
                  </span>
                }
              />
            )}
          </div>
        ))}

        <Card
          title={
            <span>
              <DollarOutlined style={{ fontSize: 16, marginRight: 5 }} />
              Resumo da compra
            </span>
          }
          className="mx-2 mt-2 mb-2"
        >
          <Row className=" bg-gray-100 h-8 items-center  rounded-sm">
            <Col span={12}>
              <Text className="text-sm text-gray-500 font-bold ml-2">Tipo Pagamento</Text>
            </Col>
            <Col span={6} className="text-center">
              <Text className="text-sm text-gray-500 font-bold">Parcela</Text>
            </Col>
            <Col span={6} className="text-right">
              <Text className="text-sm text-gray-500 font-bold mr-2">Valor</Text>
            </Col>
          </Row>
          {data.payments.map((trans: any) => (
            <>
              <Row className="h-6 items-center">
                <Col span={12}>
                  <Text className="text-sm text-gray-500 ml-2">
                    {trans.paymentMethod === 'boleto'
                      ? 'Boleto'
                      : trans.paymentMethod === 'pix'
                        ? 'Pix'
                        : 'Cartão de Crédito'}
                  </Text>
                </Col>
                <Col span={6} className="text-center">
                  <Text className="text-sm text-gray-500">{trans.installments}</Text>
                </Col>
                <Col span={6} className="text-right">
                  <Text className="text-sm text-gray-500 mr-2">{(trans.amount / 100.0).toLocaleString('pt-BR')}</Text>
                </Col>
              </Row>
            </>
          ))}
          <Divider className="mt-1 mb-1"></Divider>
          <Row>
            <Col span={18}>
              <Text className="text-sm text-gray-500 font-bold ml-2">Total</Text>
            </Col>
            <Col span={6} className="text-right">
              <Text className="text-sm text-gray-500  mr-2">{(data.amount / 100.0).toLocaleString('pt-BR')}</Text>
            </Col>
          </Row>
        </Card>

        <Row>
          <Col span={24} className="px-3">
            <Divider className="text-sm" orientation="left">
              Precisa de ajuda?
            </Divider>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="px-3 pb-8">
            <Text className="text-sm text-gray-600">
              Se precisar de ajuda com sua compra, entre em contato com{' '}
              <span className="font-bold">{data.checkout?.companyEmail}</span>
            </Text>
          </Col>
        </Row>

        <div className={className} style={{ backgroundColor: '#EBEFF4' }}>
          <Footer
            className="flex rounded-b-lg h-0 items-center justify-center"
            style={{
              backgroundColor: data?.checkout?.styleConfig?.footerBgColor
                ? data?.checkout?.styleConfig?.footerBgColor
                : '#E5E5E5',
            }}
          >
            <Image className="" preview={false} src={getCdnUrl(data?.checkout?.companyLogo)}></Image>
          </Footer>
        </div>
        {/*         < pre > {JSON.stringify(data, null, 2)}</pre> */}
      </Layout>
    </div>
  );
});

export default styled(Success)``;
