import { Button, Form, Modal } from 'antd';
import { useForm } from '../context';
import { documentMask, getCdnUrl } from '../helper';
import Terms from '../../Terms';
import { useEffect, useState } from 'react';
import extenso from 'extenso';

const TermsModal = () => {
  const [processedTermsText, setProcessedText] = useState('');
  const { showTerms, setShowTerms, checkout, form, modeAs, items, isForeigner, isOCB, ocbData } = useForm();

  const payments = Form.useWatch(['payments'], form);
  let customer = Form.useWatch(['customer'], form);

  if (!customer && isOCB) {
    customer = ocbData?.customer;
  }

  useEffect(() => {
    if (modeAs == 'preview') {
      return;
    }
    if (!checkout || !items.length) {
      return;
    }
    if (checkout?.terms?.value && checkout.terms?.type == 'text') {
      let customerType = 'NÃO DEFINIDO';

      if (isForeigner) {
        customerType = 'ESTRANGEIRO';
      } else {
        if (customer?.document) {
          const document = documentMask.clean(customer?.document);
          if (document.length == 11) {
            customerType = 'PESSOA FÍSICA';
          } else {
            customerType = 'PESSOA JURÍDICA';
          }
        }
      }

      let enrollmentFeeValue = 0;
      let subscriptionInstallments = 0;
      let subscriptionInstallmentValue = 0;
      let subscriptionAmountValue = 0;
      let subscriptionTotalAmountValue = 0;

      let enrollmentFeeValueText = '';
      let subscriptionInstallmentsText = '';
      let subscriptionInstallmentValueText = '';
      let subscriptionAmountValueText = '';
      let subscriptionTotalAmountValueText = '';

      let enrollmentFeeValueWritten = '';
      let subscriptionInstallmentsWritten = '';
      let subscriptionInstallmentValueWritten = '';
      let subscriptionAmountValueWritten = '';
      let subscriptionTotalAmountValueWritten = '';

      const firstItem = items[0];
      if (firstItem?.billingType == 'recurrence') {
        subscriptionAmountValue = (firstItem.amount * firstItem.recurrenceChargesQuantity) / 100;
        subscriptionInstallmentValue = firstItem.amount / 100;
        subscriptionInstallments = firstItem.recurrenceChargesQuantity;
        subscriptionTotalAmountValue = subscriptionAmountValue;
      }
      if (payments) {
        const hasEnrollmentFee = items.some((item) => item.type == 'enrollment_fee');
        if (hasEnrollmentFee) {
          enrollmentFeeValue = (items.find((item) => item.type == 'enrollment_fee')?.amount || 0) / 100;
          enrollmentFeeValueWritten = extenso(enrollmentFeeValue, { mode: 'currency', currency: { type: 'BRL' } });

          //se o paymentMethod for cartão
          if (payments[0].paymentMethod == 'credit_card') {
            let installmentValue = payments[0].creditCard?.installmentValue
              ? payments[0].creditCard?.installmentValue
              : payments[0].value.replace(',', '').replace('.', '');
            let installments = payments[0].creditCard?.installments ? payments[0].creditCard?.installments : 1;

            if (installmentValue) {
              installmentValue = installmentValue % 1 !== 0 ? Number(installmentValue.toFixed(2)) : installmentValue;
            }

            enrollmentFeeValue = installments * installmentValue;
            enrollmentFeeValueText =
              installments.toString() +
              'x ' +
              installmentValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

            enrollmentFeeValueWritten = `${extenso(installments)} vezes de ${extenso(installmentValue, { mode: 'currency', currency: { type: 'BRL' } })}`;
          } else {
            enrollmentFeeValueText = enrollmentFeeValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          }

          subscriptionTotalAmountValue = subscriptionTotalAmountValue + enrollmentFeeValue;
        }
      }

      subscriptionInstallmentsText = subscriptionInstallments?.toString() ?? '';
      subscriptionInstallmentValueText = subscriptionInstallmentValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      subscriptionAmountValueText = subscriptionAmountValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      subscriptionTotalAmountValueText = subscriptionTotalAmountValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

      subscriptionInstallmentsWritten = extenso(subscriptionInstallments ?? 0);
      subscriptionInstallmentValueWritten = extenso(subscriptionInstallmentValue, {
        mode: 'currency',
        currency: { type: 'BRL' },
      });
      subscriptionAmountValueWritten = extenso(subscriptionAmountValue, {
        mode: 'currency',
        currency: { type: 'BRL' },
      });
      subscriptionTotalAmountValueWritten = extenso(subscriptionTotalAmountValue, {
        mode: 'currency',
        currency: { type: 'BRL' },
      });

      const replacePlaceholders = (text: string): string => {
        return text
          .replace(/{customer_name}/g, customer?.name || 'SEU NOME')
          .replace(/{customer_email}/g, customer?.email || 'SEU EMAIL')
          .replace(/{customer_document}/g, customer?.document || 'SEU DOCUMENTO')
          .replace(/{customer_type}/g, customerType)
          .replace(/{customer_phone}/g, customer?.phone || 'SEU TELEFONE')
          .replace(/{product_name}/g, firstItem.description || 'PRODUTO')
          .replace(/{purchased_at}/g, new Date().toLocaleDateString())
          .replace(/{company_name}/g, checkout.companyName || 'NOME EMPRESA')
          .replace(/{company_email}/g, checkout.companyEmail || 'EMAIL EMPRESA')
          .replace(/{customer_postal_code}/g, customer?.address?.postalCode || 'CEP')
          .replace(/{customer_state}/g, customer?.address?.state || 'ESTADO')
          .replace(/{customer_city}/g, customer?.address?.city || 'CIDADE')
          .replace(/{customer_address}/g, customer?.address?.street || 'ENDEREÇO')
          .replace(/{customer_number}/g, isForeigner ? '' : customer?.address?.number || 'NÚMERO')
          .replace(/{customer_complement}/g, isForeigner ? '' : customer?.address?.complement || 'COMPLEMENTO')
          .replace(/{customer_neighborhood}/g, isForeigner ? '' : customer?.address?.neighborhood || 'BAIRRO')
          .replace(/{subscription_enrollment_fee_value}/g, enrollmentFeeValueText)
          .replace(/{subscription_enrollment_fee_value_written}/g, enrollmentFeeValueWritten)
          .replace(/{subscription_installments}/g, subscriptionInstallmentsText)
          .replace(/{subscription_installments_written}/g, subscriptionInstallmentsWritten)
          .replace(/{subscription_installment_value}/g, subscriptionInstallmentValueText)
          .replace(/{subscription_installment_value_written}/g, subscriptionInstallmentValueWritten)
          .replace(/{subscription_amount_value}/g, subscriptionAmountValueText)
          .replace(/{subscription_amount_value_written}/g, subscriptionAmountValueWritten)
          .replace(/{subscription_total_amount_value}/g, subscriptionTotalAmountValueText)
          .replace(/{subscription_total_amount_value_written}/g, subscriptionTotalAmountValueWritten);
      };
      // Processar o texto dos termos
      if (checkout.terms?.value) {
        const replacedTermsText = replacePlaceholders(checkout.terms.value);
        setProcessedText(replacedTermsText);
      }
    }
  }, [checkout, customer, items, payments]);

  return (
    <Modal
      title={<p style={{ fontWeight: 'bold', fontSize: '24px', marginBottom: 20 }}>Termos de Compra</p>}
      open={showTerms}
      onCancel={() => setShowTerms(false)}
      style={{ minWidth: 320, maxWidth: 1200 }}
      width="100%"
      footer={[
        <Button className="rounded-md w-20" type="default" size="large" onClick={() => setShowTerms(false)}>
          OK
        </Button>,
      ]}
    >
      {checkout?.terms?.value ? (
        <>
          {checkout?.terms?.type == 'text' && <span dangerouslySetInnerHTML={{ __html: processedTermsText }} />}
          {(checkout?.terms?.type == 'url' || checkout?.terms?.type == 'document') && (
            <iframe
              className="w-full"
              style={{
                height: '60vh',
              }}
              src={getCdnUrl(checkout?.terms?.value)}
            />
          )}
        </>
      ) : (
        <>
          <Terms
            companyName={checkout?.companyName}
            companyEmail={checkout?.companyEmail}
            productName={checkout?.items[0]?.description}
          />
        </>
      )}
    </Modal>
  );
};

export default TermsModal;
