import { Button, Collapse, Modal } from 'antd';
import { useForm } from '../context';
import { formatPhone } from '../../../helpers/functions';
import { AxiosError } from 'axios';

const RefusedModal = () => {
  const { checkout, isRefused, setRefused, error } = useForm();

  const code = (error as AxiosError<any>)?.response?.data?.code;
  const shouldShow = code != 'IDEMPOTENCY' && isRefused;

  return (
    <Modal
      closable={false}
      open={shouldShow}
      title="Não foi possível concluir sua compra!"
      footer={[
        <Button className="rounded-md w-20" type="default" size="large" onClick={() => setRefused(false)}>
          OK
        </Button>,
      ]}
    >
      <p className="my-3">Abaixo alguns possíveis motivos...</p>

      <Collapse
        collapsible="header"
        defaultActiveKey={['1']}
        ghost
        items={[
          {
            key: '1',
            label: <p className="font-bold">Dados incorretos</p>,
            children: (
              <p>
                Acontece quando as informações do cartão não foram preenchidas corretamente. Verifique desde o nome,
                passando pelo número do cartão, data de validade até o código de segurança.
              </p>
            ),
          },
        ]}
      />
      <Collapse
        collapsible="header"
        ghost
        items={[
          {
            key: '2',
            label: <p className="font-bold">Limite insuficiente</p>,
            children: (
              <p>
                Isso ocorre quando o cartão selecionado não possui limite suficiente para que a compra seja feita.
                Confira com a operadora do cartão se é possível aumentar o limite ou utilize outra forma de pagamento.
              </p>
            ),
          },
        ]}
      />
      <Collapse
        collapsible="header"
        ghost
        items={[
          {
            key: '3',
            label: <p className="font-bold">Compra suspeita</p>,
            children: (
              <p>
                Acontece em alguns casos quando a própria operadora do cartão classifica a compra como uma possível
                fraude. Costuma ocorrer quando uma compra fora do padrão, como por exemplo, uma transação de valor muito
                alto.
              </p>
            ),
          },
        ]}
      />

      {checkout?.companyEmail && checkout?.companyPhone && (
        <p className="mt-5">
          Para mais informações entre em contato com <strong>{checkout?.companyEmail}</strong> ou no telefone{' '}
          <strong>{formatPhone(checkout?.companyPhone)}</strong>
        </p>
      )}
    </Modal>
  );
};

export default RefusedModal;
