import { memo } from 'react';
import { IStyledProp } from '../../../interfaces/IStyledProp';
import styled from '@emotion/styled';
import { Row, Col, Collapse, Image, Typography } from 'antd';
import { getCdnUrl, getFrequency, moneyMask } from '../helper';
import { useForm } from '../context';

const { Text } = Typography;

const Header = memo<IStyledProp>(({ className }) => {
  const { checkout, getTotal, items, isRecurrence, firstItem, modeAs } = useForm();

  const hasOrderBump = !!items.find((item) => item.type === 'order_bump');

  const totalValue = (isRecurrence ? firstItem.amount * firstItem.quantity : getTotal()) / 100;
  const maxInstallment = checkout.installments?.[checkout.installments?.length - 1]?.installment ?? 0;
  const installmentValue = checkout.installments?.[checkout.installments?.length - 1]?.value ?? 0;

  const enrollmentFee = items.find((item) => item.type === 'enrollment_fee');

  return (
    <div className={className}>
      <Row className="px-0 ml-5 mr-5">
        {modeAs != 'transaction' && (
          <Col span={10} className="flex items-center justify-center">
            <Image
              className="p-2 rounded-2xl"
              preview={false}
              src={getCdnUrl(firstItem?.image)}
              width={150}
              height={150}
            />
          </Col>
        )}
        <Col span={modeAs != 'transaction' ? 14 : 24}>
          <Row>
            <Text className="text-xl font-bold text-gray-500">{firstItem?.description}</Text>
          </Row>
          <Row>
            {!isRecurrence && (
              <Text className="text-sm text-gray-500 mt-2">
                Em até {maxInstallment}x de{' '}
                <span className="text-lg font-bold">R$ {moneyMask.apply(installmentValue)}</span> ou{' '}
                <span className="text-lg font-bold">R$ {moneyMask.apply(totalValue)}</span> à vista
              </Text>
            )}
            {isRecurrence && enrollmentFee && maxInstallment > 1 && (
              <Text className="text-sm text-gray-500 mt-2">
                <strong>{enrollmentFee.description}</strong> em até {maxInstallment}x de{' '}
                <span className="text-lg font-bold">R$ {moneyMask.apply(installmentValue)}</span> ou{' '}
                <span className="text-lg font-bold">R$ {moneyMask.apply(getTotal() / 100)}</span> à vista
              </Text>
            )}
            {isRecurrence && enrollmentFee && maxInstallment == 1 && (
              <Text className="text-sm text-gray-500 mt-2">
                <Typography.Title level={5} className="text-lg">
                  {enrollmentFee.description?.toUpperCase()}
                </Typography.Title>
                <span className="text-lg font-bold">R$ {moneyMask.apply(getTotal() / 100)}</span> à vista
              </Text>
            )}
            {isRecurrence && (
              <Text className="text-sm text-gray-500 mt-2">
                <Typography.Title level={5} className="text-lg">
                  ASSINATURA
                </Typography.Title>
                <span className="text-lg font-bold">R$ {moneyMask.apply(totalValue)}</span>
                <div className="text-xs text-gray-500">
                  a cada {firstItem?.recurrenceInterval}{' '}
                  {getFrequency(firstItem?.recurrenceFrequency, firstItem?.recurrenceInterval)}{' '}
                  {firstItem.recurrenceChargesQuantity > 1 && (
                    <>
                      durante o período de {firstItem.recurrenceInterval * firstItem.recurrenceChargesQuantity}{' '}
                      {getFrequency(
                        firstItem?.recurrenceFrequency,
                        firstItem.recurrenceInterval * firstItem.recurrenceChargesQuantity
                      )}
                    </>
                  )}
                </div>
                {hasOrderBump && !enrollmentFee && (
                  <>
                    <Typography.Text className="block mt-2 text-sm text-gray-500">
                      Total: R$ {moneyMask.apply(getTotal() / 100)}{' '}
                    </Typography.Text>
                  </>
                )}
              </Text>
            )}
          </Row>
        </Col>
      </Row>

      <Row className="px-0 pt-2 pb-6">
        {firstItem?.details && (
          <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
            <Collapse
              className="rounded-md"
              ghost
              items={[
                {
                  key: '1',
                  label: 'DETALHES...',
                  children: (
                    <span className="text-sm text-gray-900" dangerouslySetInnerHTML={{ __html: firstItem?.details }} />
                  ),
                },
              ]}
            />
          </Col>
        )}
      </Row>
    </div>
  );
});

export default styled(Header)``;
